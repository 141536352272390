<template>
    <section class=".container">
        <Header />
        <main class="banner">
            <div>
                <p>Branding</p>
            </div>
            <h1>
                Holy O
            </h1>
            <p>
                Extensión de formulario para clientes <br class="only-mov"> potenciales <br class="only-pc">
                en Google Ads: Búsqueda,<br class="only-mov"> Display, Youtube y <br class="only-pc">Discovery
            </p>
        </main>
        <section class="parallax">
            <div></div>
        </section>
        <section class="content">
            <div>
                <p>El reto</p>
                <h2>
                    Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery
                </h2>
                <p class="only-mov">
                    ¿Saben lo que se me hizo más difícil? Quedarme encerrada en casa, y creo que no se van a oponer, 
                    pero para el primer mes de confinamiento ya no hallábamos qué hacer con nuestras vidas.
                    Y es por eso que me terminé haciendo adicta a las aplicaciones… y no se hagan, ustedes también. 
                    <br><br>
                    No los culpo, de hecho se transformaron en una especie de “alivio” durante estos meses difíciles.
                </p>
            </div>
            <div>
                <picture class="spcelyr">
                    <source srcset="../assets/landingsBrandingPortafolios/logoholy.webp" type="image/webp">
                    <img src="../assets/landingsBrandingPortafolios/logoholy.png" alt="Logo Holy O" loading="lazy">
                </picture>
            </div>
        </section>
        <section> 
            <picture class="beforePalette only-pc">
                <source srcset="../assets/landingsBrandingPortafolios/holy2.webp" type="image/webp">
                <img src="../assets/landingsBrandingPortafolios/holy2.png" alt="Imagen Decorativa">
            </picture>
            <picture class="beforePalette only-mov">
                <source srcset="../assets/landingsBrandingPortafolios/tribomv3.webp" type="image/webp">
                <img src="../assets/landingsBrandingPortafolios/tribomv3.png" alt="Imagen Decorativa">
            </picture>
            <picture class="beforePalette only-mov">
                <source srcset="../assets/landingsBrandingPortafolios/tribomv4.webp" type="image/webp">
                <img src="../assets/landingsBrandingPortafolios/tribomv4.png" alt="Imagen Decorativa">
            </picture>
        </section>
        <section class="pallete">
            <h2>Color Palette</h2>
            <div>
                <p>DARK GREEN</p>
                <h3>#213227</h3>
            </div>
            <div>
                <p>OLIVE</p>
                <h3>#C2B130</h3>
            </div>
            <div>
                <p>COTTON CANDY</p>
                <h3>#FF8BBA</h3>
            </div>
            <div>
                <p>EGGNOG</p>
                <h3>#FFE49B </h3>
            </div>
        </section>
        <section class="mosaico__video">
            <picture class="only-pc">
                <source srcset="../assets/landingsBrandingPortafolios/holy3.webp" type="image/webp">
                <img src="../assets/landingsBrandingPortafolios/holy3.png" alt="Imagen de Holy O" loading="lazy">
            </picture>
            <picture class="only-mov">
                <source srcset="" type="image/webp">
                <img src="../assets/landingsBrandingPortafolios/tribomv5.png" alt="Imagen de Holy O" loading="lazy">
            </picture>
        </section>
        <section class="mosaico">
            <div class="video__uno">
                <picture>
                    <source srcset="../assets/landingsBrandingPortafolios/holy4.webp" type="image/webp">
                <img src="../assets/landingsBrandingPortafolios/holy4.png" alt="Imagen de Holy O" loading="lazy">
                </picture>
            </div>
            <div class="mosaico__stack">
                <picture>
                    <source srcset="../assets/landingsBrandingPortafolios/holy5.webp" type="image/webp">
                <img src="../assets/landingsBrandingPortafolios/holy5.png" alt="Imagen de Holy O" loading="lazy">
                </picture>
            </div>
        </section>
        <div class="spaceFinal"></div>
        <Footer />
    </section>
</template>
<script>
import Footer from '../components/on-site/Footer.vue';
import Header from '../components/on-site/Header.vue';

export default {
    components: { Footer, Header },

    data() {
        return {
            pantalla: null,
        }
    },

    mounted() {
        window.addEventListener('scroll', function () {
            if (this.window.innerWidth >= 768) return ''

            const { height } = this.document.querySelector('.banner').getBoundingClientRect();
            const heightParallax = this.document.querySelector('.parallax').getBoundingClientRect().height;
            const parallaxPicture = document.querySelector('.parallax>div');
            const scrollPositionY = window.scrollY;

            // Define el rango de la animación según sea necesario
            const minRangeY = height; // Ajusta según sea necesario
            const maxRangeY = heightParallax; // Ajusta según sea necesario
            const tope = 100;

            // Calcula el porcentaje de desplazamiento dentro del rango
            const percentageY = (maxRangeY - minRangeY) / tope

            const factor = (93 / (1 + 173.39 * Math.E ** (-0.0062 * this.window.innerHeight)));

            const cantidadScroll = percentageY * (scrollPositionY - minRangeY) / factor

            if (scrollPositionY > minRangeY || scrollPositionY <= maxRangeY) {
                parallaxPicture.style.setProperty('--traslado', `${cantidadScroll}%`);
            }

            // && height < scrollPositionY - minRangeY
            if ((scrollPositionY >= maxRangeY && heightParallax < scrollPositionY - minRangeY) || cantidadScroll >= tope) {
                parallaxPicture.style.setProperty('--traslado', `${tope}%`);
            }

            if (scrollPositionY < minRangeY) {
                parallaxPicture.style.setProperty('--traslado', `0%`);
            }
        })

        window.addEventListener('resize', function () {
            const parallaxPicture = document.querySelector('.parallax>picture.only-mov');

            if (window.innerWidth >= 768 && this.pantalla != 'pc') {
                this.pantalla = "pc";
            } else if (window.innerWidth < 768 && this.pantalla != 'movil') {
                parallaxPicture.style.transform = `translateX(0%)`;
                resetMounted();
                this.pantalla = "movil";
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "instant",
                });
            }

        })

        let resetMounted = () => {
            this.slideReset()
        }


        if (window.innerWidth >= 768) this.pantalla = "pc";
        else if (window.innerWidth < 768) this.pantalla = "movil";
        resetMounted();
        this.buttonBlock();
    },

    methods: {
        slide(parametro) {
            const slider = document.querySelector('.slider');
            const slide = document.querySelector('.slider > picture').getBoundingClientRect().width;
            if (parametro) {
                slider.scrollLeft += slide;
            } else {
                slider.scrollLeft -= slide;
            }
        },

        buttonBlock() {
            const slider = document.querySelector('.slider');
            const buttons = document.querySelectorAll('.slider__wrapper > button');
            if (!slider) return '';

            if (slider.scrollLeft === 0) {
                buttons[0].classList.add("bloqueado");
            } else if (Math.ceil(slider.scrollLeft) === slider.scrollWidth - slider.clientWidth) {
                buttons[1].classList.add("bloqueado");
            } else {
                buttons.forEach(button => {
                    button.classList.remove("bloqueado");
                });
            }
        },

        slideReset() {
            if (this.pantalla != "movil") return '';
            const slider = document.querySelector(".slider");

            if(!slider) return '';
            setTimeout(() => {
                slider.scroll({
                    top: 0,
                    left: 0,
                    behavior: "instant"
                })
            }, 100);
        },
    },
}


</script>
<style scoped>
*,
*::before,
*::after {
    box-sizing: border-box;
}

h1,
h2,
h3,
p {
    margin: 0;
}

.bloqueado {
    opacity: 0;
}

picture {
    display: flex !important;
    width: fit-content;
    height: fit-content;
}

.container {
    display: grid;
}

.only-pc {
    display: none !important;
}

.banner {
    background-size: cover;
    background-image: url("../assets/landingsBrandingPortafolios/headerHoly.png");
    background-image: url("../assets/landingsBrandingPortafolios/headerHoly.webp");
    padding: 33.091787439613526VW 8.222222VW 149.7584541062802VW 9.66183574879227VW;
}

.banner div {
    background-color: #3702FB;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 21.014492753623188VW;
    height: 4.830917874396135VW;
}

.banner div p {
    color: #FFF;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 1.932367149758454VW;
    line-height: 135.8%;
}

h1 {
    color: #FFF;
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 400;

    font-size: 19.32367149758454VW;
    line-height: 121.3%;
    margin: 2.657004830917874VW 0 2.898550724637681VW;
}

.banner>p {
    color: #FFF;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 149.5%;
}

.parallax {
    height: 190lvh;
    margin: 0;
}

.parallax>div {
    --traslado: 0%;
    width: 100%;
    height: 100lvh;
    background-image: url("../assets/landingsBrandingPortafolios/holy1.webp");
    background-image: url("../assets/landingsBrandingPortafolios/holy1.png");
    background-size: auto 100lvh;
    background-repeat: no-repeat;
    background-position-x: var(--traslado);

    position: sticky;
    top: 0;
}

.content {
    padding: 12.318840579710146VW 0;
}

.content>div:first-of-type {
    padding: 0 11.11111111111111VW;
}

.content p:first-of-type {
    color: #EF67C2;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 125%;
    margin: 0 0 8.937198067632849VW;
}

.content h2 {
    color: #000;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 5.314009661835748VW;
    line-height: 125%;
    padding: 0 7.246376811594203VW 7.246376811594203VW 0;
}

.content p:last-of-type {
    color: #231F20;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 190.5%;
    padding: 0 7.246376811594203VW 10.38647342995169VW 0;
}

.content picture {
    overflow: hidden;
}

.content img {
    width: 68.841vw;
    aspect-ratio: 285/285;
}

.video {
    width: 100%;
    height: 100lvh;
    pointer-events: none;
    overflow: hidden;
}

.video iframe {
    width: 100%;
    height: 100%;
    transform: scale(1.1);
}

.imagen__mov picture {
    width: 100%;
    height: fit-content;
}

.imagen__mov img {
    width: 100%;
    height: 100lvh;
    object-fit: cover;
}

.pallete {
    display: grid;
}

.pallete h2 {
    color: #000;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;
    text-align: center;

    font-size: 5.314009661835748VW;
    line-height: 125%;
    margin: 21.980676328502415VW 0 20.048309178743963VW;
}

.pallete div {
    height: 45.65217391304348VW;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2077294685990339VW 0;
}

.pallete h3 {
    color: #FFF;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 8.454106280193237VW;
    line-height: 125%;
}

.pallete p {
    color: #FFF;
    text-align: center;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;

    font-size: 4.3478260869565215VW;
    line-height: 125%;
}

.pallete div:nth-of-type(4) h3,
.pallete div:nth-of-type(4) p {
    color: #231F20;
}

.pallete p {
    order: 2;
}

.pallete div:nth-of-type(1) {
    background-color: #213227;
}

.pallete div:nth-of-type(2) {
    background-color: #C2B130;
}

.pallete div:nth-of-type(3) {
    background-color: #FF8BBA;
}

.pallete div:nth-of-type(4) {
    background-color: #FFE49B;
}

.mosaico__video {
    width: fit-content;
    height: fit-content;
    margin: 21.980676328502415VW auto 0;
}

.mosaico__video img {
    width: 83.81642512077295VW;
    height: 121.014vw;
    aspect-ratio: 347/501;
}

.mosaico .video__uno {
    width: 83.81642512077295VW;
    margin: 0 auto;
}

.video__uno iframe {
    width: 100%;
    height: 100%;
}

.mosaico div {
    display: grid;
    grid-template-areas: "stack";
}

.mosaico div>* {
    grid-area: stack;
}

.mosaico div picture:first-of-type {
    margin: 0 auto;
}

.mosaico div {
    width: 83.81642512077295VW;
    margin: 0 auto;
}

.mosaico div picture:first-of-type img {
    width: 83.81642512077295VW;
}

.last__content {
    padding: 21.980676328502415VW 9.903381642512077VW;
}

.last__content h2 {
    color: #231F20;
    text-align: center;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 4.830917874396135VW;
    line-height: 125%;
}

.last__content p {
    color: #231F20;
    text-align: center;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 190.5%;
    padding: 10.869565217391305VW 3.6231884057971016VW 0;
}

.slider__wrapper {
    overflow: hidden;
    position: relative;
}

.slider {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 77.29468599033817VW;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    margin: 0 10.869565217391305VW;
    column-gap: 10.869565217391305VW;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.slider::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slider {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.slider picture {
    scroll-snap-align: start;
}

.slider picture img {
    width: 77.29468599033817VW;
    height: auto;
}

.slider__wrapper button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 9.66183574879227VW;
    height: 9.420289855072465VW;
    border-radius: 100%;
    background-color: transparent;
    border: 0.24154589371980675VW solid #987EF3;
    transition: opacity 0.3s ease-out;
}

.slider__wrapper button:nth-of-type(1) {
    left: 5.314009661835748VW;
}

.slider__wrapper button:nth-of-type(2) {
    right: 5.314009661835748VW;
    transform: rotateZ(180deg);
}

button svg {
    width: 1.4492753623188406VW;
    height: 2.1739130434782608VW;
}

.beforePalette{
    width: 100vw;
    height: 225.121vw;
}

.spaceFinal{
    margin-bottom: 31.884vw;
}

.spcelyr{
    margin: auto;
}

@media (min-width: 768px) {

    .only-mov {
        display: none !important;
    }

    .only-pc {
        display: block !important;
    }

    picture.only-pc {
        display: flex !important;
    }

    .banner {
        
        background-image: url("../assets/landingsBrandingPortafolios/headerHoly.png");
        background-image: url("../assets/landingsBrandingPortafolios/headerHoly.webp"); 
        padding: 19.885416666666668VW 7.46875VW 18.385416666666668VW 62.072916666666664VW;
    }

    .banner div {
        width: 5.9375VW;
        height: 1.3541666666666667VW;
    }

    .banner div p {
        font-size: 0.625VW;
        line-height: 135.8%;
    }

    h1 {
        font-size: 6.770833333333333VW;
        line-height: 121.3%;
        margin: 1.5625VW 0;
    }

    .banner>p {
        font-size: 0.7291666666666666VW;
        line-height: 149.5%;
        color: #FFF; 
    }

    .parallax {
        height: fit-content;
        margin: 7.8125VW auto 11.197916666666668VW;
    }

    .parallax>div {
        --traslado: 0;
        width: 83.33333333333334VW;
        height: 37.34375VW;
        background-size: cover;
        animation: none;
        position: static;
        margin: 0 auto;
    }

    .content {
        padding: 0 12.708vw 15.46875VW 10.416666666666668VW;
        display: flex;
        column-gap: 4.166666666666666VW;
    }

    .content>div:first-of-type {
        padding: 0;
    }

    .content p:first-of-type {
        font-size: 1.0416666666666665VW;
        line-height: 125%;
        margin: 1.4583333333333333VW 0 0;
    }

    .content h2 {
        font-size: 1.8229166666666667VW;
        line-height: 125%;
        padding: 0;
        margin: 2.604166666666667VW 0 5.729166666666666VW;
    }

    .content p:last-of-type {
        width: 32.96875VW;
        font-size: 0.8333333333333334VW;
        line-height: 190.5%;
        padding: 0;
    }

    .content img {
        width: 30.771vw;
        aspect-ratio: 590.812/590.812;
        transform: none;
    }

    .video {
        width: 83.33333333333334VW;
        height: 83.33333333333334VW;
        margin: 0 auto;
    }

    .video iframe {
        transform: scale(1);
    }

    .imagen__mov {
        display: none;
    }

    .beforePalette{
        width: 83.33333vw;
        height: 83.33333vw;
        padding-left: 8.333vw;
        padding-right: 8.333vw;
    }

    .pallete {
        grid-template-columns: repeat(4, 1fr);
    }

    .pallete h2 {
        font-size: 1.354vw;
        line-height: 125%;
        grid-column: 1 / -1;
        margin: 4.739583333333333VW 0 4.322916666666667VW;
    }

    .pallete div {
        height: 44.895833333333336VW;
        gap: 0.26041666666666663VW 0;
        padding: 0 0 12.760416666666666VW;
        justify-content: center;
        align-items: end;
        flex-direction: row;
    }

    .pallete h3 {
        font-size: 4.167vw;
        line-height: 125%;
        order: 2;
        transform: rotate(-180deg);
        writing-mode: vertical-lr;
    }

    .pallete p {
        font-size: 1.354vw;
        line-height: 125%;
        transform: rotate(-180deg);
        writing-mode: vertical-lr;
    }

    .pallete p {
        order: 1;
    }

    .mosaico__video {
        margin: 21.980676328502415VW auto 0;
    }

    .mosaico__video img {
        width: 88.02083333333334VW;
    }


    .mosaico {
        display: flex;
        width: 88.02083333333334VW;
        margin: 0 auto;
    }

    .mosaico .video__unoc {
        margin: 0;
    }

    .mosaico .video__uno {
        width: 43.75VW;
        aspect-ratio: 840/762;
        pointer-events: none;
        overflow: hidden;
    }

    .video__uno iframe {
        transform: scale(1.1);
    }

    .mosaico div picture:first-of-type {
        margin: 0;
    }

    .mosaico div {
        width: 44.27083333333333VW;
        margin: 0;
    }

    .mosaico div picture:first-of-type img {
        width: 44.27083333333333VW;
        aspect-ratio: 850/762;
    }

    .last__content {
        padding: 11.302083333333334VW 31.145833333333332VW;
    }

    .last__content h2 {
        font-size: 1.3020833333333335VW;
        line-height: 125%;
    }

    .last__content p {
        font-size: 0.8333333333333334VW;
        line-height: 190.5%;
        padding: 3.3854166666666665VW 1.4583333333333333VW 0;
    }

    .slider__wrapper {
        display: grid;
        justify-items: center;
        padding-bottom: 14.583333333333334VW;
    }

    .slider__wrapper>picture {
        width: 88.02083333333334VW;
        height: 44.739583333333336VW;
    }

    .only-pc img {
        width: 88.02083333333334VW;
        height: auto;
        aspect-ratio: 1690/859;
    }

    .spaceFinal{
        margin-bottom: 18.333vw;
    }
}
</style>